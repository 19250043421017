import { computed, ref } from 'vue'
import { RANK_LIST } from '@/type/rank/rank.type'
import { useUserStore } from '@/pinia/user/user.pinia'

type DefaultRank = {
    rankName: RANK_LIST;
    imageMedal: any;
    image: any;
    text: string;
    textTh: string;
}

type Rank = {
    id: RANK_LIST,
    name: string,
    minTotal: number,
    maxTotal: number
}

type CurrentRank = Rank & DefaultRank

export const useRank = () => {
	const userStore = useUserStore()
	const rankList = ref<Rank[]>([
		{
			id: RANK_LIST.NEWBIE,
			name: 'Newbie',
			'minTotal': 0,
			'maxTotal': 999
		},
		{
			'id': RANK_LIST.BRONZE,
			'name': 'Bronze',
			'minTotal': 1000,
			'maxTotal': 4999
		},
		{
			'id': RANK_LIST.SILVER,
			'name': 'Silver',
			'minTotal': 5000,
			'maxTotal': 9999
		},
		{
			'id': RANK_LIST.GOLD,
			'name': 'Gold',
			'minTotal': 10000,
			'maxTotal': 29999
		},
		{
			'id': RANK_LIST.PLATINUM,
			'name': 'Platinum',
			'minTotal': 30000,
			'maxTotal': 49999
		},
		{
			'id': RANK_LIST.DIAMOND,
			'name': 'Diamond',
			'minTotal': 50000,
			'maxTotal': 10000000
		}
	])

	const defaultRank = ref<DefaultRank[]>([
		{
			rankName: RANK_LIST.NEWBIE,
			imageMedal: require('@/assets/images/rank/newbie-medal.png'),
			image: require('@/assets/images/rank/newbie.png'),
			text: 'Newbie',
			textTh: 'มือใหม่'
		},
		{
			rankName: RANK_LIST.BRONZE,
			imageMedal: require('@/assets/images/rank/bronze-medal.png'),
			image: require('@/assets/images/rank/bronze.png'),
			text: 'Bronze',
			textTh: 'ทองแดง',
		},
		{
			rankName: RANK_LIST.SILVER,
			imageMedal: require('@/assets/images/rank/silver-medal.png'),
			image: require('@/assets/images/rank/silver.png'),
			text: 'Silver',
			textTh: 'เงิน'
		},
		{
			rankName: RANK_LIST.GOLD,
			imageMedal: require('@/assets/images/rank/gold-medal.png'),
			image: require('@/assets/images/rank/gold.png'),
			text: 'Gold',
			textTh: 'ทอง'
		},
		{
			rankName: RANK_LIST.PLATINUM,
			imageMedal: require('@/assets/images/rank/platinum-medal.png'),
			image: require('@/assets/images/rank/platinum.png'),
			text: 'Platinum',
			textTh: 'แพลตตินั่ม'
		},
		{
			rankName: RANK_LIST.DIAMOND,
			imageMedal: require('@/assets/images/rank/diamond-medal.png'),
			image: require('@/assets/images/rank/diamond.png'),
			text: 'Diamond',
			textTh: 'เพรช'
		}
        
	])

	const mergeRankListWithDefault = computed(() => {
		return rankList.value.map((rank) => {
			const findExistItemInDefaultRank = defaultRank.value.find(each => each.rankName === rank.id)
			return {
				...rank,
				...findExistItemInDefaultRank
			}
		})
	})

	const lastSeasonRank = computed<CurrentRank | undefined>(() => {
		if (userStore.permission.shopCurrentBadgeRanking) {
			const findRank = mergeRankListWithDefault.value.find((each) => each.rankName === userStore.permission.shopCurrentBadgeRanking)
			return findRank as CurrentRank
		}
		return undefined
	})

	const currentRank = computed((): CurrentRank & { rankingCounting: number, maxCount: number } => {

		const findRank = mergeRankListWithDefault.value.find(each => each.rankName === rankFromUserStore.value?.badgeRanking)
		return {
			...findRank,
			...rankFromUserStore.value
		} as CurrentRank & { rankingCounting: number, maxCount: number }
	})

	const nextRank = computed(() => {
		const indexCurrentRank = rankList.value.findIndex((each: Rank) => each.name === currentRank.value.name)
		return rankList.value[indexCurrentRank + 1]
	})

	const rankFromUserStore = computed(() => {
		// const mockBadgeInfo =  {
		// 	badgeRanking: RANK_LIST.DIAMOND,
		// 	rankingCounting: 10000,
		// 	maxCount: 80000
		// }
		// return mockBadgeInfo
		return userStore.permission.shopBadgeInfo
	})

	return {
		currentRank,
		rankList,
		mergeRankListWithDefault,
		rankFromUserStore,
		nextRank,
		lastSeasonRank,
	}
}